import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';

import { Input } from '@components/FormComponents';
import { ProductCard } from '@components/ProductCard';
import { useSearch } from '@hooks/useSearch';
import { SearchBannerCTA } from '@interfaces/IBannerCTA';
import { CONFIG, SEARCH_INDEXES } from '@lib/constants';
import { cdnClient as client } from '@lib/sanityClient';

import { SearchResultCardCompactBlog } from './SearchResultCardCompactBlog';
import { SearchResultsLinkList } from './SearchResultsLinkList';

const fetcher = async (url) => {
  const query = `//groq
    *[_type == "siteSettings" && !(_id in path("drafts.**")) 
    && dateTime(menuBanner->startsAt) < dateTime(now())
    && dateTime(menuBanner->expiresAt) > dateTime(now())][0]
    {
      menuBanner->{
        title,
        image {
          asset-> {...}
        },
        links[]{
          ...,
          "noFollow": coalesce(noFollow, false),
          internalLink->{slug, title}
        }
      }
    }
  `;

  const result = await client.fetch(query);

  if (result) {
    return result.menuBanner;
  } else {
    const error = new Error('Unable to fetch search banner CTA');
    throw error;
  }
};

function Modal({ query }: { query: string }): ReactElement {
  const {
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    data,
    counts,
    error,
    size,
    setSize,
  } = useSearch({
    index: '*',
    query,
    pageSize: 4,
  });

  const productHits =
    data?.find((d) => d.index === SEARCH_INDEXES.PRODUCTS)?.nbHits ?? 0;
  const blogHits =
    data?.find((d) => d.index === SEARCH_INDEXES.BLOGS)?.nbHits ?? 0;

  return (
    <div
      id="autocomplete-modal"
      className="top-14 absolute left-0 right-0 z-2 mx-auto my-0 grid max-h-[90vh] w-compact cursor-auto grid-cols-[4fr_1fr] overflow-auto border border-t-0 border-solid border-grey-mid bg-white p-0 text-grey-darkest opacity-100 shadow-bigDrop transition-all delay-200 duration-150 ease-in-out dark:bg-black dark:text-white xl:w-maxWidth"
    >
      <section className="overflow-y-auto border-0 border-r-2 border-solid border-orange-light p-4">
        <div id="products" className="mb-4">
          <div className="flex flex-row items-baseline">
            <h4 className="m-0 inline-block font-secondary text-2xl font-bold uppercase text-black dark:text-grey-light">
              Products
            </h4>

            {productHits > 0 && (
              <h5 className="cursor-pointer p-2 font-secondary text-lg font-semibold uppercase text-grey-dark underline decoration-grey-mid decoration-1 underline-offset-[0.1em] duration-500 hover:text-black hover:decoration-orange hover:decoration-3 hover:duration-200 dark:text-white">
                <Link
                  href={`/search-results-page?q=${query}&index=${SEARCH_INDEXES.PRODUCTS}`}
                  as={`/search-results-page?q=${query}&index=${SEARCH_INDEXES.PRODUCTS}`}
                >
                  View {productHits} product{productHits > 1 && 's'}
                </Link>
              </h5>
            )}
          </div>

          {productHits === 0 ? (
            <p>No products found</p>
          ) : (
            <div className="ga-autocomplete ga-autocomplete-products grid grid-cols-2 gap-2">
              {(
                data?.find((d) => d.index === SEARCH_INDEXES.PRODUCTS)?.hits ??
                []
              )
                .slice(0, CONFIG.autocompleteProducts) // slice to the number we'll show
                .map((item) => (
                  <div key={item.objectID} onClick={(e) => e.stopPropagation()}>
                    <ProductCard product={item} />
                  </div>
                ))}
            </div>
          )}
        </div>

        <div id="blogs">
          <div className="flex flex-row items-baseline">
            <h4 className="m-0 inline-block font-secondary text-2xl font-bold uppercase text-black dark:text-grey-light">
              Blogs
            </h4>

            {blogHits > 0 && (
              <h5 className="cursor-pointer p-2 font-secondary text-lg font-semibold uppercase text-grey-dark underline decoration-grey-mid decoration-1 underline-offset-[0.1em] duration-500 hover:text-black hover:decoration-orange hover:decoration-3 hover:duration-200 dark:text-white">
                <Link
                  href={`/search-results-page?q=${query}&index=${SEARCH_INDEXES.BLOGS}`}
                  as={`/search-results-page?q=${query}&index=${SEARCH_INDEXES.BLOGS}`}
                >
                  View {blogHits} blog{blogHits > 1 && 's'}
                </Link>
              </h5>
            )}
          </div>

          {blogHits === 0 ? (
            <p>No Blogs found</p>
          ) : (
            <div className="ga-autocomplete ga-autocomplete-blogs grid grid-cols-2 gap-2">
              {(data?.find((d) => d.index === SEARCH_INDEXES.BLOGS)?.hits ?? [])
                .slice(0, CONFIG.autocompleteProducts) // slice to the number we'll show
                .map((item) => (
                  <div key={item.objectID} onClick={(e) => e.stopPropagation()}>
                    <SearchResultCardCompactBlog hit={item} />
                  </div>
                ))}
            </div>
          )}
        </div>
      </section>

      <section className="grid grid-rows-2 p-4">
        <div>
          <h4 className="m-0 inline-block font-secondary text-2xl font-bold uppercase text-black dark:text-grey-light">
            Categories
          </h4>
          <SearchResultsLinkList
            items={
              data
                ? (data.find((el) => el.index === SEARCH_INDEXES.CATEGORIES)
                    ?.hits ?? []).map((c) => ({...c, title: c.parent ? `${c.parent} > ${c.title}` : c.title}))
                : []
            }
            noResultsMessage={'No categories found'}
          />
        </div>

        <div>
          <h4 className="m-0 inline-block font-secondary text-2xl font-bold uppercase text-black dark:text-grey-light">
            Pages
          </h4>
          <SearchResultsLinkList
            items={
              data
                ? [
                    ...(data.find((el) => el.index === SEARCH_INDEXES.PAGES)
                      ?.hits ?? []),
                    ...(data
                      .find((el) => el.index === SEARCH_INDEXES.AMBASSADORS)
                      ?.hits?.map((a) => ({
                        ...a,
                        title: `${a.name} (Ambassador)`,
                      })) ?? []),
                    ...(data
                      .find((el) => el.index === SEARCH_INDEXES.AUTHORS)
                      ?.hits?.map((a) => ({
                        ...a,
                        title: `${a.name} (Expert)`,
                      })) ?? []),
                  ]
                : []
            }
            noResultsMessage={'No pages found'}
          />
        </div>
      </section>

      {/* {searchBanner && (
        <section className="p-1.5 [grid-area:banner]">
          <Banner
            cta={searchBanner}
            className={'autocomplete-cta-link'}
            linkKeyPrefix={'autocomplete-cta-text-link'}
          />
        </section>
      )} */}
    </div>
  );
}

export function Autocomplete(): ReactElement {
  // periodically get search CTA
  const { data: searchBanner } = useSWR<SearchBannerCTA>(
    '/api/searchBannerCTA',
    fetcher,
    {
      refreshInterval: 1200000, // 20 minutes
      revalidateOnFocus: false,
    }
  );

  const [open, setOpen] = useState(false);

  const rhfMethods = useForm<any>({
    mode: 'onTouched',
  });

  const query = rhfMethods.watch('query');

  const router = useRouter();

  useEffect(() => {
    if (query) {
      setOpen(true);
    }
  }, [query]);

  // close the search modal on route change
  useEffect(() => {
    const handleRouteChange = (url, shallow) => {
      setOpen(false);
      rhfMethods.resetField('query');
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  // Close on click outside
  useEffect(() => {
    const clickListener = (e) => {
      if (!e.target.closest('#autocomplete-modal')) {
        setOpen(false);
      }
    };

    document.addEventListener('click', clickListener);

    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, []);

  // Close on escape
  useEffect(() => {
    const escapeListener = (e) => {
      if (e.key == 'Escape') {
        setOpen(false);
      }
    };

    document.addEventListener('keydown', escapeListener);

    return () => {
      document.removeEventListener('keydown', escapeListener);
    };
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();
    setOpen(false);
    router.push(`/search-results-page?q=${rhfMethods.getValues().query}`);
  };

  return (
    <FormProvider {...rhfMethods}>
      <form onSubmit={rhfMethods.handleSubmit(onSubmit)}>
        <Input name="query" type="search" label="" />
      </form>
      {open && <Modal query={query} />}
    </FormProvider>
  );
}
